import { AppDispatch, CustomDispatch } from "redux/store";
import {
  callLogOut,
  callRefreshToken,
  checkResponse,
  formatError,
} from "utils";
import {
  fetchDeviceQuery,
  fetchDoctorQuery,
  fileRecordCreateQuery,
  fileRecordListQuery,
  noteCategoryListQuery,
  noteCreateQuery,
  noteListQuery,
  patientListQuery,
  patientProfileQuery,
  patientRightDataQuery,
  prescribedMedicationListQuery,
  fetchPatientWeightGraph,
  fetchPatientHeartRate,
  fetchPatientInsulin,
  testCategoriesHistoryQuery,
  testCategoriesQuery,
  testRecordCreateQuery,
  testRecordListQuery,
  fetchBloodPressureGraph,
  fetchGlucoseGraph,
  fetchFoodLogGraph,
  addPatientQuery,
  diagnosisListQuery,
  medicationListQuery,
  editPatientQuery,
  handleStatusToggleQuery,
  reassignDoctorQuery,
  getLabTestRecordChartQuery,
  insuranceListQuery,
  rightManualRecordDataQuery,
  rightDeviceRecordDataQuery,
  rightMedicationRecordDataQuery,
  rightFoodRecordDataQuery,
  updateReadinessStatusQuery,
  labTestListQuery,
  updateContentTagQuery,
  fetchPatientStep,
  fetchPatientSleep,
  addCampaignQuery,
  fetchPatientSleepHistory,
  rightStressRecordDataQuery,
} from "./query";
import { toast } from "react-toastify";
import {
  CHANGE_STATE,
  CHANGE_STATE_UPLOAD_REPORT,
  SAVE_BOOKMARK,
  SAVE_QUICK_CHAT,
} from "redux/StateChange/action";

export const PATIENT_LIST = "user/patient_list";
export const PATIENT_PROFILE = "user/patient_profile";
export const RESET_PROFILE = "user/reset_profile";
export const RESET_RIGHT_RECORD_DATA = "RESET_RIGHT_RECORD_DATA";
export const RESET_STEPS_HISTORY = "RESET_STEPS_HISTORY";
export const RESET_SLEEP = "RESET_SLEEP";
export const RESET_SLEEP_HISTORY = "RESET_SLEEP_HISTORY";
export const PRESCRIBED_MEDICATION_LIST = "user/prescribed_medication_list";
export const FETCH_MEDICATION_LIST = "FETCH_MEDICATION_LIST";
export const PATIENT_RIGHT_DATA = "user/patient_right_data";
export const PATIENT_RIGHT_DATA_LOADING = "PATIENT_RIGHT_DATA_LOADING";
export const RIGHT_RECORD_DATA = "RIGHT_RECORD_DATA";
export const UPDATE_READINESS_STATUS = "UPDATE_READINESS_STATUS";
export const UPDATE_CONTENT_TAG = "UPDATE_CONTENT_TAG";
export const FETCH_DOCTOR = "user/fetch_doctor";
export const FETCH_SECONDARY_DOCTOR = "user/FETCH_SECONDARY_DOCTOR";
export const FETCH_PRIMARY_SECONDARY_DOCTOR = "FETCH_PRIMARY_SECONDARY_DOCTOR";
export const SET_PATIENT_ID = "user/setPatientId";
export const FETCH_DEVICE = "user/fetch_device";
export const NOTE_CATEGORY_LIST = "user/noteCategoryList";
export const NOTE_LIST = "user/noteList";
export const WEIGHT_DATA = "user/patient_weight";
export const BLOOD_PRESSURE_DATA = "user/bloodPressure";
export const HEART_RATE_DATA = "user/patient_heart_rate";
export const STEPS = "user/step";
export const STRESS = "user/stress";
export const STEPS_HISTORY = "user/step_history";
export const SLEEP = "user/sleep";
export const SLEEP_HISTORY = "user/sleep_history";
export const INSULIN_DATA = "user/patient_insulin";
export const FILE_RECORD_LIST = "user/fileRecordList";
export const ERRORS = "user/errors";
export const COMPLETE = "COMPLETE";
export const TEST_CATEGORIES = "user/test_categories";
export const TEST_CATEGORIES_HISTORY = "user/test_categories_history";
export const LAB_TEST_LIST = "LAB_TEST_LIST";
export const TEST_RECORD_LIST = "user/test_record_list";
export const GLUCOSE_DATA = "user/glucose";
export const FOODLOG_DATA = "user/foodLog";
export const ADD_PATIENT = "user/add_patient";
export const EDIT_PATIENT = "EDIT_PATIENT";
export const DIAGNOSIS_LIST = "user/diagnosis_list";
export const MEDICATION_LIST = "user/medication_list";
export const INSURANCE_LIST = "user/insurance_list";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const REASSIGN_DOCTOR = "REASSIGN_DOCTOR";
export const LAB_TEST_RECORD_CHART = "LAB_TEST_RECORD_CHART";
export const RESET_LAB_TEST_RECORD_CHART = "RESET_LAB_TEST_RECORD_CHART";
export const EDIT_PROFILE = "EDIT_PROFILE";

export const patientBookmark =
  (id: string) => async (dispatch: AppDispatch) => {
    dispatch({
      type: SAVE_BOOKMARK,
      payload: id,
    });
  };
export const patientQuickChat =
  (id: string) => async (dispatch: AppDispatch) => {
    dispatch({
      type: SAVE_QUICK_CHAT,
      payload: id,
    });
  };

export const patient_list =
  (data: {
    page_size: string;
    page: string;
    search: string;
    primary_staff?: string;
    secondary_staff?: string;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    const res = await patientListQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: PATIENT_LIST,
        payload: { data: response.data, pagination: response.pagination },
      });
      dispatch({
        type: CHANGE_STATE,
        payload: "true",
      });
    } else if (response.code === 401) {
      const refreshResponse = await callRefreshToken();
      if (refreshResponse == "SUCCESS") {
        dispatch(patient_list(data));
      } else {
        callLogOut(dispatch);
      }
      dispatch({
        type: CHANGE_STATE,
        payload: "false",
      });
    } else {
      const errors: { [key: string]: string } = formatError(
        response.errorMessages || []
      );
      dispatch({
        type: ERRORS,
        payload: errors,
      });
      dispatch({
        type: CHANGE_STATE,
        payload: "false",
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const patient_profile =
  (data: { id: string }) => async (dispatch: AppDispatch) => {
    const res = await patientProfileQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: PATIENT_PROFILE,
        payload: { data: response.data },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const prescribed_medication_list =
  (data: { id: string | string[] | undefined }) =>
  async (dispatch: AppDispatch | CustomDispatch) => {
    const res = await prescribedMedicationListQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: PRESCRIBED_MEDICATION_LIST,
        payload: { data: response.data },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const fetch_medication_list =
  (data: { id: string | string[] | undefined }) =>
  async (dispatch: AppDispatch) => {
    const res = await prescribedMedicationListQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: FETCH_MEDICATION_LIST,
        payload: { data: response.data },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const fetch_patient_right_data =
  (data: { id: string | string[] | undefined }) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: PATIENT_RIGHT_DATA_LOADING,
      payload: { loading: true },
    });
    const res = await patientRightDataQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: PATIENT_RIGHT_DATA,
        payload: { data: response.data, id: data.id },
      });
      dispatch({
        type: PATIENT_RIGHT_DATA_LOADING,
        payload: { loading: false },
      });
    } else {
      dispatch({
        type: PATIENT_RIGHT_DATA_LOADING,
        payload: { loading: false },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const fetchPrimaryTeam =
  (data: { type: string; patient_user: string }) =>
  async (dispatch: AppDispatch | CustomDispatch) => {
    const res = await fetchDoctorQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: FETCH_DOCTOR,
        payload: { data: response.data },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const fetchSecondaryTeam =
  (data: { type: string; patient_user: string }) =>
  async (dispatch: AppDispatch | CustomDispatch) => {
    const res = await fetchDoctorQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: FETCH_SECONDARY_DOCTOR,
        payload: { data: response.data },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const fetch_primary_secondary_doctor =
  (data: { type?: string; patient_user: string }) =>
  async (dispatch: AppDispatch | CustomDispatch) => {
    const res = await fetchDoctorQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: FETCH_PRIMARY_SECONDARY_DOCTOR,
        payload: { data: response.data, type: data.type },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const reset_profile = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: RESET_PROFILE,
  });
  dispatch({
    type: COMPLETE,
    payload: "",
  });
};

export const reset_right_record_data = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: RESET_RIGHT_RECORD_DATA,
  });
  dispatch({
    type: COMPLETE,
    payload: "",
  });
};

export const reset_steps_history_and_sleep_history =
  () => async (dispatch: AppDispatch) => {
    dispatch({ type: RESET_STEPS_HISTORY });
    dispatch({ type: RESET_SLEEP_HISTORY });
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const reset_sleep_chart = () => async (dispatch: AppDispatch) => {
  dispatch({ type: RESET_SLEEP });
  dispatch({
    type: COMPLETE,
    payload: "",
  });
};

export const fetch_device =
  (data: { user: number }) => async (dispatch: AppDispatch) => {
    const res = await fetchDeviceQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: FETCH_DEVICE,
        payload: { data: response.data },
      });
    }
  };

export const setPatientId =
  (data: { id: number }) => async (dispatch: AppDispatch) => {
    dispatch({
      type: SET_PATIENT_ID,
      payload: { data: data },
    });
  };

export const note_category_list = () => async (dispatch: AppDispatch) => {
  const res = await noteCategoryListQuery();
  const response = await checkResponse(res);
  if (response.success) {
    dispatch({
      type: NOTE_CATEGORY_LIST,
      payload: { data: response.data },
    });
  }
  dispatch({
    type: COMPLETE,
    payload: "",
  });
};

export const note_list =
  (data: {
    page_size: string;
    page: string;
    user: string;
    start_datetime: string;
    end_datetime: string;
    note: string;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    const res = await noteListQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: NOTE_LIST,
        payload: { data: response.data, pagination: response.pagination },
      });
      dispatch({
        type: CHANGE_STATE,
        payload: "true",
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const note_create =
  (data: { note: string; user: string }) => async (dispatch: any) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    const res = await noteCreateQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      toast.success(response.message);
      dispatch({
        type: CHANGE_STATE,
        payload: "true",
      });
      return response;
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const file_record_list =
  (data: {
    page_size: number;
    page: string;
    user: string;
    filename: string;
    start_datetime: string;
    end_datetime: string;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    const res = await fileRecordListQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: FILE_RECORD_LIST,
        payload: { data: response.data, pagination: response.pagination },
      });
      dispatch({
        type: CHANGE_STATE,
        payload: "true",
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const file_record_create =
  (data: {
    is_showable: boolean;
    file: File;
    note: string;
    user: string;
    recorded_at: string;
  }) =>
  async (dispatch: any) => {
    const res = await fileRecordCreateQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: CHANGE_STATE_UPLOAD_REPORT,
        payload: "true",
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };
export const patient_weight =
  (data: {
    user: string;
    period: string;
    annotation: number;
    annotation_in_period: number;
  }) =>
  async (dispatch: AppDispatch) => {
    const res = await fetchPatientWeightGraph(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: WEIGHT_DATA,
        payload: response.data,
      });
    }
  };
export const patientHeartRate =
  (data: {
    user: string;
    period: string;
    annotation: number;
    annotation_in_period: number;
    device_id: number;
  }) =>
  async (dispatch: AppDispatch) => {
    const res = await fetchPatientHeartRate(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: HEART_RATE_DATA,
        payload: response.data,
      });
    }
  };

export const patientStepHistory =
  (data: {
    user: string;
    period: string;
    annotation: number;
    annotation_in_period: number;
    device_id: number;
    start_datetime: Date;
    end_datetime: Date;
  }) =>
  async (dispatch: AppDispatch) => {
    const res = await fetchPatientStep(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: STEPS_HISTORY,
        payload: {
          data: response.data.items,
          loadMore: response.data.items.length === 0,
        },
      });
    } else {
      const errors: any = formatError(response.errorMessages || []);
      dispatch({
        type: ERRORS,
        payload: errors,
      });
    }
  };

export const patientStep =
  (data: {
    user: string;
    period: string;
    annotation: number;
    annotation_in_period: number;
    device_id: number;
    start_datetime: Date;
    end_datetime: Date;
  }) =>
  async (dispatch: AppDispatch) => {
    const res = await fetchPatientStep(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: STEPS,
        payload: {
          data: response.data.items,
          loadMore: response.data.items.length === 0,
        },
      });
    } else {
      const errors: any = formatError(response.errorMessages || []);
      dispatch({
        type: ERRORS,
        payload: errors,
      });
    }
  };

export const patientSleep =
  (data: {
    user: string;
    period: string;
    annotation: number;
    annotation_in_period: number;
    device_id: number;
    start_datetime: Date;
    end_datetime: Date;
  }) =>
  async (dispatch: AppDispatch) => {
    const res = await fetchPatientSleep(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: SLEEP,
        payload: {
          data: response.data,
          loadMore: false,
        },
      });
    } else {
      const errors: any = formatError(response.errorMessages || []);
      dispatch({
        type: ERRORS,
        payload: errors,
      });
    }
  };

export const patientSleepHistory =
  (data: {
    user: string;
    device_id: number;
    start_datetime: Date;
    end_datetime: Date;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: SLEEP_HISTORY,
      payload: { data: [], loadMore: false, loading: true },
    });
    const res = await fetchPatientSleepHistory(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: SLEEP_HISTORY,
        payload: {
          data: response?.data?.items,
          loadMore: response?.data?.items?.length === 0,
          loading: false,
        },
      });
    } else {
      const errors: any = formatError(response.errorMessages || []);
      dispatch({
        type: SLEEP_HISTORY,
        payload: { data: [], loadMore: false, loading: false },
      });
      dispatch({
        type: ERRORS,
        payload: errors,
      });
    }
  };

export const patientInsulin =
  (data: {
    user: string;
    period: string;
    annotation: number;
    annotation_in_period: number;
  }) =>
  async (dispatch: AppDispatch) => {
    const res = await fetchPatientInsulin(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: INSULIN_DATA,
        payload: response.data,
      });
    }
  };

export const lab_test_list = () => async (dispatch: AppDispatch) => {
  const res = await labTestListQuery();
  const response = await checkResponse(res);
  if (response.success) {
    dispatch({
      type: LAB_TEST_LIST,
      payload: response.data,
    });
  }
  dispatch({
    type: COMPLETE,
    payload: "",
  });
};

export const test_categories =
  (data: { user: number }) => async (dispatch: AppDispatch) => {
    const res = await testCategoriesQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: TEST_CATEGORIES,
        payload: { data: response.data },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const test_categories_history =
  (data: {
    user: any;
    cardId: string;
    start_datetime: string;
    end_datetime: string;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    const res = await testCategoriesHistoryQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: TEST_CATEGORIES_HISTORY,
        payload: { data: response.data },
      });
      dispatch({
        type: CHANGE_STATE,
        payload: "true",
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const test_record_create =
  (data: {
    is_showable: boolean;
    file: File;
    description: string;
    user: string;
    data: {};
    id: string;
    recorded_at: string;
  }) =>
  async (dispatch: any) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    const res = await testRecordCreateQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: CHANGE_STATE,
        payload: "true",
      });
      return response;
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
    return response;
  };

export const test_record_list =
  (data: {
    page_size: string;
    page: string;
    user: string;
    search: string;
    start_datetime: string;
    end_datetime: string;
    test: string;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    const res = await testRecordListQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: TEST_RECORD_LIST,
        payload: { data: response.data, pagination: response.pagination },
      });
      dispatch({
        type: CHANGE_STATE,
        payload: "false",
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };
export const getBloodPressureGraph =
  (data: {
    user: string;
    period: string;
    annotation: number;
    annotation_in_period: number;
  }) =>
  async (dispatch: AppDispatch) => {
    const res = await fetchBloodPressureGraph(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: BLOOD_PRESSURE_DATA,
        payload: response.data,
      });
    }
  };

export const getGlucoseGraph =
  (data: {
    user: string;
    period: string;
    annotation: number;
    annotation_in_period: number;
  }) =>
  async (dispatch: AppDispatch) => {
    const res = await fetchGlucoseGraph(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: GLUCOSE_DATA,
        payload: response.data,
      });
    }
  };

export const getFoodLogGraph =
  (data: {
    user: string;
    period: string;
    annotation: number;
    annotation_in_period: number;
    no_paginate: boolean;
  }) =>
  async (dispatch: AppDispatch) => {
    const res = await fetchFoodLogGraph(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: FOODLOG_DATA,
        payload: response.data,
      });
    }
  };

export const addCampaign =
  (data: {
    name: string;
    sender_user_id: any[];
    message: string;
    attachment?: File;
    criteria?: { field: string; value: any }[];
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    const res = await addCampaignQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: CHANGE_STATE,
        payload: "false",
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
    return res;
  };

export const addPatient =
  (data: {
    phone_number: string;
    first_name: string;
    last_name: string;
    birth_date: string;
    diagnoses: { id: number; value: string; label: string }[];
    medications: { id: number; value: string; label: string }[];
    primary_staff_users: {
      value: string;
      label: string;
      img: string;
      user: number;
      id: number;
    }[];
    secondary_staff_users: {
      value: string;
      label: string;
      img: string;
      user: number;
      id: number;
    }[];
    gender: string;
    nationality?: {
      value: string;
      label: string;
    };
    identity_no: string;
    passport_no: string;
    insurance_no: string;
    clinic_no: string;
    insurance_company: { id: number; value: string; label: string };
    location?: {
      value: string;
      label: string;
    };
    // identity_card: File | null;
    email: string;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    const res = await addPatientQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: ADD_PATIENT,
        payload: response.data,
      });
      dispatch({
        type: CHANGE_STATE,
        payload: "true",
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
    return res;
  };

export const editPatient =
  (data: {
    user: number;
    phone_number: string;
    first_name: string;
    last_name: string;
    birth_date: string;
    diagnoses: { id: number; value: string; label: string }[];
    medications: { id: number; value: string; label: string }[];
    primary_staff_users: {
      value: string;
      label: string;
      img: string;
      user: number;
      id: number;
    }[];
    secondary_staff_users: {
      value: string;
      label: string;
      img: string;
      user: number;
      id: number;
    }[];
    gender: string;
    nationality: {
      value: string;
      label: string;
    };
    identity_no: string;
    passport_no: string;
    insurance_no: string;
    clinic_no: string;
    insurance_company: { id: number; value: string; label: string };
    location: {
      value: string;
      label: string;
    };
    // identity_card: File | null;
    email: string;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    const res = await editPatientQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: EDIT_PATIENT,
        payload: response.data,
      });
      dispatch({
        type: CHANGE_STATE,
        payload: "true",
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
    return res;
  };

export const diagnosis_list =
  (search?: string) => async (dispatch: AppDispatch) => {
    const res = await diagnosisListQuery(search);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: DIAGNOSIS_LIST,
        payload: response.data,
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const medication_list =
  (search?: string) => async (dispatch: AppDispatch) => {
    const res = await medicationListQuery(search);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: MEDICATION_LIST,
        payload: response.data,
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const insurance_list = () => async (dispatch: AppDispatch) => {
  const res = await insuranceListQuery();
  const response = await checkResponse(res);
  if (response.success) {
    dispatch({
      type: INSURANCE_LIST,
      payload: response.data,
    });
  }
  dispatch({
    type: COMPLETE,
    payload: "",
  });
};

export const handleStatusToggle =
  (data: { id: number; status: string }) => async (dispatch: AppDispatch) => {
    const res = await handleStatusToggleQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      toast.success("Status updated successfully.");
      dispatch({
        type: UPDATE_STATUS,
        payload: data,
      });
    }
  };

export const reassignDoctor =
  (
    data: {
      id: number;
      patient_staff_relationship: "primary" | "secondary";
    }[],
    patientId: string
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    const res = await reassignDoctorQuery(data, patientId);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: REASSIGN_DOCTOR,
        payload: response.data,
      });
      dispatch({
        type: CHANGE_STATE,
        payload: "true",
      });
      dispatch({
        type: ERRORS,
        payload: {},
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
    return res;
  };

export const getLabTestRecordChart =
  (data: {
    id: number;
    name: string;
    user: string;
    start_datetime: string;
    end_datetime: string;
  }) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: CHANGE_STATE,
      payload: "loading",
    });
    const res = await getLabTestRecordChartQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: LAB_TEST_RECORD_CHART,
        payload: { name: data.name, data: response.data, id: data.id },
      });
      dispatch({
        type: CHANGE_STATE,
        payload: "true",
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
    return res;
  };

export const resetLabTestRecordChart =
  (data: { name: string }) => async (dispatch: AppDispatch) => {
    dispatch({
      type: RESET_LAB_TEST_RECORD_CHART,
      payload: data.name,
    });
  };

export const edit_profile =
  (data: { id: string }) => async (dispatch: AppDispatch) => {
    const res = await Promise.all([
      patientProfileQuery(data),
      prescribedMedicationListQuery(data),
      fetchDoctorQuery({ type: "1", patient_user: data.id }),
      fetchDoctorQuery({ type: "2", patient_user: data.id }),
      diagnosisListQuery(),
      medicationListQuery(),
      insuranceListQuery(),
    ]);
    const response = res.map((res) => res.data);
    const finalResponse = {
      profile: response[0].body,
      fetch_medications: response[1].body,
      fetch_primary_doctor: { data: response[2].body, type: "1" },
      fetch_secondary_doctor: { data: response[3].body, type: "2" },
      diagnosis_list: response[4].body,
      medication_list: response[5].body,
      insurance_list: response[6].body,
    };
    dispatch({
      type: EDIT_PROFILE,
      payload: finalResponse,
    });
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const right_manual_record_data =
  (data: {
    manual_type: string;
    page: string;
    page_size: string;
    user: string;
  }) =>
  async (dispatch: AppDispatch) => {
    const res = await rightManualRecordDataQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: RIGHT_RECORD_DATA,
        payload: { data: response.data, pagination: response.pagination },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };
export const right_device_record_data =
  (data: {
    page: string;
    page_size: string;
    user: string;
    device_type: string;
    data_type: string;
  }) =>
  async (dispatch: AppDispatch) => {
    const res = await rightDeviceRecordDataQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: RIGHT_RECORD_DATA,
        payload: { data: response.data, pagination: response.pagination },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };
export const right_medication_record_data =
  (data: {
    page: string;
    page_size: string;
    user: string;
    medication_type: string;
  }) =>
  async (dispatch: AppDispatch) => {
    const res = await rightMedicationRecordDataQuery(data);
    const response = await checkResponse(res);

    if (response.success) {
      dispatch({
        type: RIGHT_RECORD_DATA,
        payload: { data: response.data, pagination: response.pagination },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };
export const right_food_record_data =
  (data: { page: string; page_size: string; user: string }) =>
  async (dispatch: AppDispatch) => {
    const res = await rightFoodRecordDataQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: RIGHT_RECORD_DATA,
        payload: { data: response.data, pagination: response.pagination },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const patientStressHistory =
  (data: { start_datetime: Date; end_datetime: Date; user: string }) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: STRESS,
      payload: {
        data: [],
        pagination: [],
        loading: true,
      },
    });
    const res = await rightStressRecordDataQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: STRESS,
        payload: {
          data: response.data,
          pagination: response.pagination,
          loading: false,
        },
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };
export const update_readiness_status =
  (data: { user: string; readiness: string }) =>
  async (dispatch: AppDispatch) => {
    const res = await updateReadinessStatusQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: UPDATE_READINESS_STATUS,
        payload: true,
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const update_content_tag =
  (data: { user: string; tag: string }) => async (dispatch: AppDispatch) => {
    const res = await updateContentTagQuery(data);
    const response = await checkResponse(res);
    if (response.success) {
      dispatch({
        type: UPDATE_CONTENT_TAG,
        payload: true,
      });
    }
    dispatch({
      type: COMPLETE,
      payload: "",
    });
  };

export const reset_readiness_status = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: UPDATE_READINESS_STATUS,
    payload: false,
  });
};

export const reset_Content_Tag = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: UPDATE_CONTENT_TAG,
    payload: false,
  });
};
